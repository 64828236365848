/**
  Typography mixins
*/

@mixin text-display-m() {
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.88px;
}

@mixin text-heading-m() {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.24px;
}

@mixin text-heading-s() {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.16px;
}

@mixin text-sub-heading-l() {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.24px;
}

@mixin text-sub-heading-m() {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

@mixin text-body() {
  font-style: normal;
  font-weight: 500;
}

@mixin text-body-l() {
  @include text-body();
  font-size: 16px;
  line-height: 24px;
}

@mixin text-body-bold-l() {
  @include text-body-l();
  font-weight: 600;
}

@mixin text-body-m() {
  @include text-body();
  font-size: 14px;
  line-height: 20px;
}

@mixin text-body-bold-m() {
  @include text-body-m();
  font-weight: 600;
}

@mixin text-body-s() {
  @include text-body();
  font-size: 12px;
  line-height: 16px;
}

@mixin text-body-bold-s() {
  @include text-body-s();
  font-weight: 600;
}

/**
  Typography classes
*/

.display-m {
  @include text-display-m();
}

.heading-m {
  @include text-heading-m();
}

.heading-s {
  @include text-heading-s();
}

.sub-heading-l {
  @include text-sub-heading-l();
}

.sub-heading-m {
  @include text-sub-heading-m();
}

.body-l {
  @include text-body-l();
}

.body-bold-l {
  @include text-body-bold-l();
}

.body-m {
  @include text-body-m();
}

.body-bold-m {
  @include text-body-bold-m();
}

.body-s {
  @include text-body-s();
}

.body-bold-s {
  @include text-body-bold-s();
}

.label {
  @include text-body-s();
  line-height: 14px;
}

.label-bold {
  @include text-body-bold-s();
  line-height: 14px;
}

.label-s {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

/**
  Typography color classes
*/

.primary {
  color: $baseAccent;
}
