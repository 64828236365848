/** Checkboxes */
input[type='checkbox']:not(.ag-checkbox-input) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  display: none;
  visibility: hidden;
  left: -9999px;
  + label {
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    padding-left: 26px;
    position: relative;
    vertical-align: middle;
    min-height: 20px;
    &:before {
      content: '';
      display: block;
      left: 0;
      top: 0;
      min-width: 20px;
      min-height: 20px;
      border: $borderM solid $gray;
      -webkit-border-radius: $borderM;
      -moz-border-radius: $borderM;
      border-radius: $radiusS;
      position: absolute;
    }
    &:after {
      content: '';
      display: block;
      left: 7px;
      top: 2px;
      width: 7px;
      height: 13px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      position: absolute;
      -webkit-transition: transform 0.2s ease;
      -moz-transition: transform 0.2s ease;
      -ms-transition: transform 0.2s ease;
      -o-transition: transform 0.2s ease;
      transition: transform 0.2s ease;
      -webkit-transform: rotate(45deg) scale(0);
      -moz-transform: rotate(45deg) scale(0);
      -ms-transform: rotate(45deg) scale(0);
      -o-transform: rotate(45deg) scale(0);
      transform: rotate(45deg) scale(0);
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -ms-transform-origin: center;
      -o-transform-origin: center;
      transform-origin: center;
    }
    + input[type='checkbox'] + label {
      margin-left: 40px;
    }
  }
  &:checked + label:before {
    border: none;
    background-color: $baseAccent;
  }

  &:disabled {
    &:before {
      cursor: not-allowed;
    }
    & + label {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:checked + label {
      &:before {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &:checked + label:after {
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

//If Checkbox is disabled, gray the border
input[type='checkbox']:disabled + label:before {
  opacity: 0.6;
  cursor: not-allowed;
}
